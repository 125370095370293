import { Component, inject, Injector, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { RootState } from '@store/index';

@Component({ template: '' })
export abstract class ReactiveComponent implements OnDestroy {
  protected store: Store<RootState> = inject(Store);
  protected injector = inject(Injector);
  protected destroyed$: Subject<void> = new Subject();

  ngOnDestroy() {
    this.destroyed$.next();
  }
}
